.nbResult {
  font-size: 12px;
  font-style: italic;
}
.editIcon {
  margin-right: 16px;
  cursor: pointer;
}
.cursorPointer {
  cursor: pointer;
}
.premiumLogo {
  margin-top: 8px;
}
.tableRow {
  height: 51px;
  &:hover {
    background-color: #71BAC9 !important;
    & .MuiTableCell-body {
      color: "#fff";
    }
  }
}
.busyProgress {
  color: #FCB614  !important;
  top: 50%;
  left: 50%;
}
.ackdrop {
  position: absolute;
  z-index: 1;
  color: #FCB614;
}