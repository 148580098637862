@import '../../../assets/styles/variables.scss';

.addEditUserFlp {
  margin-left: 16px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;

  form {
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 24px;

      &:nth-child(8) {
        margin-top: 4px;

        ul {
          input {
            margin: auto;
          }
        }

        div {
          margin-top: 0;
        }
      }

      label {
          margin-left: 4px;
      }

      ul {
          input {
            &:focus {
              border: none;
              outline: none;
            }
        }
      }

      input, select {
        border: 1px solid $csd-gray-light;
        margin: 4px;
        width: 310px;
        height: 24px;
        border-radius: 4px;
        &:focus {
            border: 2px solid $csd-blue;
            outline: none;
        }
      }

      select {
        height: 28px;
        width: 316px;
      }
    }
  }
}
