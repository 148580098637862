@import "../../assets/styles/variables.scss";

.pageFooter {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 20px;
  height: 25px;
  width: 100%;
  background-color: #b1b1b1;
  color: white;
  font-size: 12px;
  letter-spacing: 0.5px;
  // padding: 0 10px;
  position: fixed;
  bottom: 0;

  & p {
    margin: 0;
    text-indent: -12px;
    padding-left: 12px;
  }
}

.termsOfUseLink {
  min-width: fit-content;
  color: white;
  text-decoration: none;
  cursor: pointer;
}