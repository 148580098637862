.containerHorizontal{
  display: flex;
  width: 100%;

  .zip {
    cursor: pointer;
    width: 34%;
    margin-right: 11px;
  }

  .country {
    width: 67%;
    height: 36px;
    margin: 4px 0px;
    border: 1px solid #ced4da;
    border-radius: 0px;
  }

  .city {
    width: 50%;
    margin-right: 11px;
  }

  .region {
    width: 50%;
  }
}