.field {
    margin-bottom: 20px;
}

.textInput {
    div {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      margin-top: 5px;
      border-radius: 1px;
    }
  }
