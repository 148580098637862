.add_bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.nb_result {
  font-size: 12px;
  font-style: italic;
}

.pagination {
  margin-top: 23px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 1rem;
}
.button {
  margin-bottom: 16px;
  width: 140px !important;
  height: 45px;
  padding: 10;
  color: "#fff";
  &:hover {
    background-color: "#36B0C9";
  }
}