// BluePrint JS
@import "../../assets/styles/variables.scss";


.landingBackground {
  background-image: url("../../assets/images/background.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background-size: cover;
}

.floatLandingContainer {
  display: flex;
  height: 100%;
}

.floatLandingChild {
  width: 50%;
  float: left;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-bottom: 17%;
  flex-direction: column;
}

.logoLandingStyle {
  background-image: url("../../assets/images/dexis-logo.png");
  background-repeat: no-repeat;
  width: 480px;
  height: 50px;
  transform: translate(3%, 36%);
  background-size: contain;
}

.betaLogoLandingStyle {
  background-image: url("../../assets/images/beta.svg");
  background-repeat: no-repeat;
  width: 50px;
  height: 50px; 
  transform: translate(470px);
  position: absolute;
  margin-right: 5px;
  margin-left: 20px;
}

.textLandingStyle {
  font-family: Arial;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: 85px;
}

.globImage {
  background-image: url("../../assets/images/globe.png");
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  width: 100%;
  height: 121%;
  transform: translate(-2%, -19%);
  background-size: contain;
}

// workaround because MUIButton applies its default style
.loginLandingBtn {
  min-width: 150px !important;
  height: 42px !important;
  color: #FFFF !important;
  border-radius: 10px !important;
  background-color:#FF5F30 !important;
  z-index: 10;
  font-weight: 700 !important;
  font-size: 16px !important;
  margin: 30px auto !important;
  &:hover,
  &:active {
    background-color: #cf330b !important;
  }
}

  //.loginButton:global(.#{$ns}-button)
  button {
    color: $csd-white;
    margin-left: auto;
    background-color: $csd-purple;
    background-image: none;

    &:hover,
    &:active {
      background-color: $csd-purple;
    }
  }

