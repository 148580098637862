.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    font-weight: bold;
  }

  .field {
    margin: 4px 0px;
    width: calc(100% - 22px);
    height: 24px;
    border: 1px solid #AFAFAF;
    padding: 5px 10px;

  }
  .field:hover {
    border-color: black;
  }
}