.confirmDelete {
  text-align: center;

  .generalWarning {
    width: 100px;
    height: 100px;
    margin: auto;
  }

  .header {
    font-size: 18px;
    font-weight: 700;
  }

  .body {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
  }
}
