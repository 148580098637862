.container {
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.input {
  width: 400px;
  height: 36px !important;
  border-radius: 4px !important;
}
.searchInput {
  width: 100%;
}
.searchContainer {
  margin: auto 16px;
  width: calc(100% - 10px); // 6 button + 4 margin
}
.paper {
  display: flex;
  justify-content: space-between;
  width: 400px;
  height: 36px !important;
  border-radius: 4px !important;
}

.iconButton {
  transform: scale(1, 1);
}
.iconButtonHidden {
  transform: scale(0, 0);
  & > icon {
    opacity: 0;
  }
}
.searchIconButton {
  margin-right: -36px !important;
}
