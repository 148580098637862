@import "../../assets/styles/variables.scss";

.tabs {
    flex-grow: 1;
    display: flex;
    // min-height: 90vh;

    .help {
        position: fixed;
        bottom: 15px;
        padding: 16px;
        cursor: pointer
    }
    .box {
        background-color: $csd-white-smoke;
        box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.15);
        border-radius: 20px 0 0 20px;
        margin-top: 1px;
        width: 100%;

        .header {
            background-color: $csd-white;
            padding: 8px 0 0 24px;
            border-top-left-radius: 20px 20px;
            min-height: 96px;

            .title {
                font-size: 18px;
                font-weight: bold;
                line-height: 24px;
                margin-top: 0;
                margin-bottom: 24px
            }
            .subTitle {
                display: flex;
                font-size: 14px;
                font-weight: bold;
                
                button {
                    color: $csd-blue;
                    text-transform: none;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: left;
                }
            }
        }
    }
}

/* material-ui */
.MuiTabs-flexContainerVertical {
    button {
        min-height: 14px;
        min-width: 48px;
    }
}
.Mui-selected {
    background-color: $csd-blue !important;
}
.MuiTab-wrapper {
    flex-direction: inherit !important;
    justify-content: start !important;
}
.MuiTabs-vertical {
    width: 48px;
    padding-top: 20px;
    display: inline-table !important;
}
img{
    margin-left: auto;
    margin-right: auto;
}