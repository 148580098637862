.root {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0;
    
    & > * {
      width: 100%,
    }
  }
  
  .container {
    max-height: 650px
  }
  
  .chartContainer {
    width: 100%;
    display: flex;
    min-height: 450px;
    align-items: flex-start;
    margin: auto;
  }
  
  .legendRoot {
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  .legendItem {
    display: inline-block;
    margin-right: 10;
  }
  
  .legendSvg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4;
  }
  
  .header {
    display: flex;
    margin-bottom: 16px;
  }
  
  .dateRange {
    color: #EB6039;
  }
  
  .countHeader {
    writing-mode: vertical-lr;
    padding: 0 !important;
  }
  
  .countCell {
    text-align: center !important;
    padding: 0 !important;
  }

  .valueSpan{
    background-color: #B4E0D7;
    padding: 5px 10px 5px 10px;
  }
  
  .totalCell {
    text-align: center !important;
    padding: 0 !important;
    font-weight: bold !important;
  }
  
  .totalLabel {
    font-weight: bold !important;
  }
  
  .calendar {
    position: relative;
    margin-left: 20px;
  
    & > button {
      background-color: white;
      color: #36b0c9;
      border-radius: 8px;
      cursor: pointer;
      width: fit-content;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  
  .calendarPopup {
    min-width: 975px;
  }
  
  .calendarContainer {
    position: absolute;
    z-index: 999;
    background-color: white;
    padding: 10px 20px;
    top: 50px;
    right: 3%;
    max-width: 100%;
    line-height: 1.125em;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 0 3px 0 #ddd;
    border: 1px solid #ddd;
    border-radius: 12px;
  
    
  
    & .rdrStaticRangeLabel {
      font-size: 12px;
    }
  
    & .rdrDateRangePickerWrapper button {
      border: 0;
    }
  
    & .calendar-arrow {
      content: "";
      position: absolute;
      top: 160px;
      border-right: 7px solid blue;
      z-index: 9999;
    }
  
    & button {
      border: none;
      background-color: white;
      color: black;
      margin: 0;
      border: 0;
      outline: none;
      font-size: 1em;
    }
  
    & .react-calendar__navigation {
      display: flex;
      height: 40px;
      margin-bottom: 10px;
      color: black;
  
      & .react-calendar__navigation__arrow {
        font-weight: 600;
        font-size: 18px;
      }
  
      & button > span {
        font-size: 14px;
      }
    }
  
    & .react-calendar__month-view__weekdays {
      display: flex;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.75em;
      margin-bottom: 8px;
    }
  
    & .react-calendar__month-view__days {
      display: flex;
      flex-wrap: wrap;
  
      & > button {
        flex: 0 0 14.2857%;
        overflow: hidden;
        height: 32px;
      }
    }
  }

  .active {
    background-color: #36B0C9 !important;
    color: white !important;
  }