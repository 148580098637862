.root {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0;
    
    & > * {
      width: 100%,
    }
  }
  
  .nbResult {
    font-size: 12px;
    font-style: italic;
  }
  
  .pagination {
    margin-top: 23px;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 1rem;
  }
  
  .loader {
    position: absolute;
    text-align: center;
    right: 0;
    left: 0;
  }
  
  .button {
    margin-bottom: 16px;
    // width: 120px !important;
    height: 40px;
    padding: 10px !important;
    color: #fff;
    &:hover {
      background-color: #36B0C9;
    }
  }