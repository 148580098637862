.home {
    display: flex;
    align-items: center;
    height: 100%;
  
    .content {
      width: 100%;
      height: 100%;
      background-color: white;
      border: solid 1px #f0f0f0;
      display: flex;
      flex-flow: column;

      .hcentered {
        width: 100%;
        justify-content: center;
        flex: 0 1 auto;
    }

    .footerContainer {
      overflow: hidden;
    }

    
    
      // & > * {
      //   flex: 1;
      // }
    }


  }