// CSD Brand colors
$csd-yellow: #ddb71b;
$csd-gray: #898a8d;
$csd-gray-dark: #262626;
$csd-gray-light: #7b7b7b;
$csd-silver: #afafaf;
$csd-blue: #36b0c9;
$csd-blue-light: #e2f8fc;
$csd-orange: #ff5100;
$csd-pink: #d41367;
$csd-purple: #612166;
$csd-white: #fff;
$csd-white-smoke: #f5f5f5;

// Custom colours
$avatar-blue: #0078FF;
$accepted-green: #40C140;

// Override the default BP font
$pt-font-family: "Arial";

