.root {
  flex-wrap: wrap;
  padding-top: 0;
}

.nb_result {
  font-size: 12px;
  font-style: italic;
}

.pagination {
  margin-top: 23px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 1rem;
  width: 100%;
  padding: 0;
  margin: 10px;
}
