@import "./assets/styles/variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.csd-yellow {
  color: $csd-yellow;
}

.csd-gray {
  color: $csd-gray;
}

.csd-blue {
  color: $csd-blue;
}

.csd-orange {
  color: $csd-orange;
}

.csd-ping {
  color: $csd-pink;
}

.csd-purple {
  color: $csd-purple;
}

.csd-white {
  color: $csd-white !important;
}

.ellipsis {
  width: autot;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.centered {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-text {
	font-size: 12px;
	color: $csd-orange;
	margin-top: 0;
}

.error-input {
  border: 1px solid $csd-orange !important;
}

.cursor-pointer {
  cursor: pointer;
}

/* search bar */

.searchbar {
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 16px;

  .searchbar-input {
    width: 400px;
    height: 36px;
    border-radius: 4px;
  }

  .searchbar-btn {
    background-color: $csd-blue;
    font-size: 12px;
    margin-left: 16px;
    width: 111px !important;
    height: 32px;
    padding: 0;
    color: $csd-white;
    &:hover {
      background-color: $csd-blue;
    }        
  }
}

/* add bar */ // ex: add member

.add-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  div {
    font-size: 12px;
    height: 32px;
    padding: 0;
    text-align: left;

    button {
      color: $csd-white;

      &:hover {
        background-color: $csd-blue;
      }
    }
  }

  .nbResult {
    font-size: 12px;
    font-style: italic;
  }
}

/* table container */
.table-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
  
  & > * {
    width: 100%,
  }
}

/* pagination */
.pagination {
  margin-top: 23px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 1rem;
}

/* customizedDialog */
.dialog-actions {
  padding: 30px;
  text-align: center;

  .btn-cancel, .btn-confirm {
    border-radius: 4px;
    margin: 0 5px;
    min-width: 80px;
    min-height: 24px;
    padding: 4px 8px;
  }

  .btn-cancel {
    background-color: $csd-blue-light;
    color: $csd-gray-dark;
  }

  .btn-confirm {
    background-color: $csd-blue;
    color: $csd-white;

    &:disabled {
      background-color: $csd-blue-light;
    }

    &:hover {
      color: $csd-blue;
    }
  }

}
