@import '../../assets/styles/variables.scss';

.input-tag {
	border-radius: 4px;
	display: flex;
	flex-wrap: wrap;
	min-height: 28px;

	.input-tag__list {
		display: inline-flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		width: 100%;

		li {
			align-items: center;
			border-radius: 12px;
			background-color: $csd-blue-light;
			display: flex;
			font-weight: 300;
			list-style: none;
			padding: 1px 0px 2px 8px;
			height: 20px;
			margin: 2px;

			button {
				min-width: 25px !important;
			}
		}
	
		input {
			display: flex;
			flex-grow: 1;
			border: none;
			&:focus {
				outline: transparent;
			}
		}
	}
}

.helper-text {
	font-size: 11px;
	font-style: italic;
	color: $csd-gray-light;
}

.border-orange {
	border: 1px solid $csd-orange;
}

.border-black {
	border: 1px solid black;
}