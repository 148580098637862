@import "../../assets/styles/variables.scss";

.confirm, .cancel {
  button {
    border-radius: 4px;
    min-width: 80px;
    height: 24px;
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
  }
}
.confirm {
  button {
    background-color: $csd-blue;
    color: $csd-white;
    &:hover,
    &:active {
      background-color: $csd-blue;
    }
    &:disabled {
      background-color: $csd-blue-light;
      color: $csd-gray-light;
    }
  }
}
.cancel {
  button {
    background-color: $csd-blue-light;
    border-color: $csd-blue-light;
    color: $csd-gray-dark;
    &:hover,
    &:active {
      background-color: $csd-blue-light;
    }
  }
}
