@import "../../assets/styles/variables.scss";

.pageHeader {
  background: url('../../assets/images/navbar-bg.png');
  height: 56px;
  display: flex;
  padding: 0 10px;
  flex-grow: 1;
  box-shadow: 0 1px 1px rgb(0 0 0 / 25%);
  line-height: 56px;
  background-size: 100% 100%;
  grid-column-end: 3;
  justify-content: space-between;
  grid-column-start: 1;
  background-position: 50%;
}

.logo {
  display: flex;
  img {
    height: 27px;
    padding-top: 13px;
    padding-right: 13px;
  }
  h1 {
    font-family: Arial;
    font-weight: normal;
    font-size: 24px;
    margin: 0;
  }
}

.betaLogo {
  display: flex;
  img {
    height: 34%;
    padding-top: 14px;
    translate: -10%;
  }
}

.account {
  display: flex;
  align-items: flex-end;
  margin-bottom: 7px;
}

.root {
  flex-grow: 1;
}
.title {
  flex-grow: 1;
}
.selectEmpty {
  // margin-top: theme.spacing(2),
}
.avatar {
  margin-right: 15px;
  background-color: #2196f3 !important;
}