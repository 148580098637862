@import "../../../assets/styles/variables.scss";

.confirmDisable {
  margin: auto;
  text-align: center;

  .generalWarning {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    margin: auto;
    margin-top: 25px;
  }

  .header {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 700;
  }

  .body {
    font-size: 14px;
    font-weight: 400;
    margin-top: 11px !important;
    line-height: 25px;
  }
}
