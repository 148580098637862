@import "../../../assets/styles/variables.scss";

.addMember {
  margin-left: 16px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;

  form {
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 24px;

      input {
        margin: 4px;
        width: 310px;
        height: 24px;
        border-radius: 4px;
      }
    }
    .customerRef {
      div {
        margin-top: 4px;
      }
    }
  }
}
