@import "../../assets/styles/variables.scss";

.mainLayout {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 30px 10%;

  & h1 {
    text-align: center;
    border-bottom: 2px solid #ff5f30;
    padding-bottom: 10px;
  }

  & h2 {
    text-align: center;
  }
}