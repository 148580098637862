.root {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
  
  & > * {
    width: 100%,
  }
}
.nbResult {
  font-size: 12px;
  font-style: italic;
}
.pagination {
  margin-top: 23px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 1rem;
}
.logo {
  max-height: 48px;
  max-width: 164px;
}
.indicationLogo {
  margin-right: 16px;
  cursor: pointer;
  height: 50px;
  width: 50px;
}
.countriesCell {
  max-width: 200px;
  overflow-wrap: break-word;
}